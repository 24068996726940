import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useSessionHash } from "./useSessionHash";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
function Dapp(){
  const { sessionHash } = useSessionHash()
    return (
      <GoogleOAuthProvider clientId="306335457063-nbueeodfoigdpbuc4h163kdk5u39ho26.apps.googleusercontent.com" nonce={sessionHash} key={sessionHash}>
			<App />
		</GoogleOAuthProvider>
    )
}
root.render(
			<Dapp />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
