import React, { useState, useRef, useEffect } from 'react';
import radialSpaceCycle from './original_c6dd6d33a2b984b939ee4a043ab3f915.gif'
import "./App.css"
import sequence from './SequenceEmbeddedWallet'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useSessionHash } from "./useSessionHash";
import { SequenceIndexer} from '@0xsequence/indexer'
const ENDPOINT = 'https://silent-bird-b6b4.ehwaz.workers.dev'

const Spinner = () => {
  return <div className="spinner"></div>;
};

const ImageGrid = ({ images, setView }: any) => {
  return (
    <div className="grid">
      {images.map((image: any, index: any) => (
        <div key={index} className="box">
          <img src={image} alt={`Content ${index + 1}`} />
        </div>
      ))}
      <div className="box add-new" onClick={() => {setView(3)}}>
        <div className="plus-sign">+</div>
      </div>
    </div>
  );
};

let address: any = null

function App() {
  const [nodes, setNodes] = useState<any>([]);
  const [edges, setEdges] = useState<any>([]);
  const [image, setImage] = useState<any>(null);
  const [view, setView] = useState(0)
  const canvasRef: any = useRef(null);
  const [address, setAddress] = useState<any>(null)
  const [txHash, setTxHash] = useState<any>(null)
  const [meshes, setMeshes] = useState([])
  const [isMinting, setIsMinting] = useState(false)

  const handleFileUpload = (e: any) => {
    setNodes([])
    setEdges([])
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const img: any = new Image();
      img.onload = () => setImage(img);
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleCanvasClick = (e: any) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
  
    const clickedEdgeIndex = edges.findIndex((edge: any) => isNearEdge(x, y, edge));
    if (clickedEdgeIndex !== -1) {
      // Remove the edge that was clicked
      setEdges(edges.filter((_: any, index: any) => index !== clickedEdgeIndex));
    } else if (x <= canvas.width / 2) {
      // Add a new node and connect it if clicked on the image side
      const newNode = { x, y };
      const newEdges = nodes.map((node: any) => ({ from: node, to: newNode }));
      setNodes([...nodes, newNode]);
      setEdges([...edges, ...newEdges]);
    }
  };

  const isNearEdge = (clickX: any, clickY: any, edge: any, tolerance = 5) => {
    const { x: x1, y: y1 } = edge.from;
    const { x: x2, y: y2 } = edge.to;
  
    // Calculate distances
    const dx = x2 - x1;
    const dy = y2 - y1;
    const lenSq = dx * dx + dy * dy;
    const param = lenSq ? ((clickX - x1) * dx + (clickY - y1) * dy) / lenSq : -1;
  
    let nearestX, nearestY;
    
    if (param < 0) {
      nearestX = x1;
      nearestY = y1;
    } else if (param > 1) {
      nearestX = x2;
      nearestY = y2;
    } else {
      nearestX = x1 + param * dx;
      nearestY = y1 + param * dy;
    }
  
    const distX = clickX - nearestX;
    const distY = clickY - nearestY;
    const distance = Math.sqrt(distX * distX + distY * distY);
  
    return distance <= tolerance;
  };

  const signIn = () => {
    setView(1)
  }

  const downloadCanvas = () => {
    // Create a temporary canvas
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = canvasRef.current.width / 1.4; // Adjusted width for the network only
    tempCanvas.height = canvasRef.current.height;
    const ctx: any = tempCanvas.getContext('2d');

    // Calculate and apply the bounding box of the graph
    const minX = Math.min(...nodes.map((node: any) => node.x));
    const maxX = Math.max(...nodes.map((node: any) => node.x));
    const minY = Math.min(...nodes.map((node: any) => node.y));
    const maxY = Math.max(...nodes.map((node: any) => node.y));
    const graphWidth = maxX - minX;
    const graphHeight = maxY - minY;
    const graphCenterX = minX + graphWidth / 2;
    const graphCenterY = minY + graphHeight / 2;
    const canvasCenterX = tempCanvas.width / 2;
    const canvasCenterY = tempCanvas.height / 2;
    const offsetX = canvasCenterX - graphCenterX;
    const offsetY = canvasCenterY - graphCenterY;

    // Draw the network (nodes and edges) on the temporary canvas centered
    nodes.forEach((node: any) => {
        ctx.fillStyle = 'blue';
        ctx.beginPath();
        ctx.arc(node.x + offsetX, node.y + offsetY, 5, 0, 2 * Math.PI);
        ctx.fill();
    });
    edges.forEach((edge: any) => {
        ctx.setLineDash([5, 3]);
        ctx.strokeStyle = 'blue';
        ctx.beginPath();
        ctx.moveTo(edge.from.x + offsetX, edge.from.y + offsetY);
        ctx.lineTo(edge.to.x + offsetX, edge.to.y + offsetY);
        ctx.stroke();
        ctx.setLineDash([]);
    });

    // Generate data URL from the temporary canvas and trigger download
    const imageURI = tempCanvas.toDataURL("image/png");
    const link = document.createElement('a');
    link.download = "network-diagram.png";
    link.href = imageURI;

    // Manually dispatch the event to handle mobile restrictions
    document.body.appendChild(link); // Append link to the body
    const event = new MouseEvent('click', {
        view: window,
        bubbles: false,
        cancelable: true
    });
    link.dispatchEvent(event);
    link.remove(); // Clean up and remove the link from the document
};

  
  

  useEffect(() => {
    if(canvasRef.current){

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

    // Draw image on left side
    if (image) {
      ctx.drawImage(image, 0, 0, canvas.width / 2, canvas.height);
    }

    // Draw nodes and edges on both sides of the canvas
    nodes.forEach((node: any) => {
      // Draw nodes on the image
      ctx.fillStyle = 'red'; // Node color on image
      ctx.beginPath();
      ctx.arc(node.x, node.y, 5, 0, 2 * Math.PI);
      ctx.fill();

      // Draw mirrored nodes on schematic
      ctx.fillStyle = 'blue'; // Node color on schematic
      const mirroredX = node.x + canvas.width / 2;
      ctx.beginPath();
      ctx.arc(mirroredX, node.y, 5, 0, 2 * Math.PI);
      ctx.fill();
    });

    edges.forEach((edge: any) => {
      ctx.setLineDash([5, 3]); // Set dashed lines: 5 pixels solid, 3 pixels gap
      // Draw edges on the image
      ctx.strokeStyle = 'red'; // Edge color on image
      ctx.beginPath();
      ctx.moveTo(edge.from.x, edge.from.y);
      ctx.lineTo(edge.to.x, edge.to.y);
      ctx.stroke();

      // Draw mirrored edges on schematic
      ctx.strokeStyle = 'blue'; // Edge color on schematic
      ctx.beginPath();
      ctx.moveTo(edge.from.x + canvas.width / 2, edge.from.y);
      ctx.lineTo(edge.to.x + canvas.width / 2, edge.to.y);
      ctx.stroke();
      ctx.setLineDash([]); // Reset to solid lines
    });
  }

  }, [nodes, edges, image]); // Redraw when nodes, edges, or image change

 

  const handleGoogleLogin = async (tokenResponse: CredentialResponse) => {
    console.log(tokenResponse)
    const res = await sequence.signIn({
      idToken: tokenResponse.credential!
    }, "Hypermesh")
    setAddress(res.wallet)
    setView(1)
  }

  const { sessionHash } = useSessionHash()

  useEffect(() => {
    setTimeout(async () => {
      if(await sequence.isSignedIn()){
        setAddress(await sequence.getAddress())
        setView(1)
      } else {
        console.log('not signed in')
      }
    }, 0)
  }, [])

  useEffect(() => {
    setImage(null)
    setTxHash(null)
  }, [view])

  const [error, setError]= useState<any>(null)

  const mint = async () => {
     // Create a temporary canvas
     const tempCanvas = document.createElement('canvas');
     tempCanvas.width = canvasRef.current.width / 1.4; // Adjusted width for the network only
     tempCanvas.height = canvasRef.current.height;
     const ctx: any = tempCanvas.getContext('2d');
 
     // Calculate and apply the bounding box of the graph
     const minX = Math.min(...nodes.map((node: any) => node.x));
     const maxX = Math.max(...nodes.map((node: any) => node.x));
     const minY = Math.min(...nodes.map((node: any) => node.y));
     const maxY = Math.max(...nodes.map((node: any) => node.y));
     const graphWidth = maxX - minX;
     const graphHeight = maxY - minY;
     const graphCenterX = minX + graphWidth / 2;
     const graphCenterY = minY + graphHeight / 2;
     const canvasCenterX = tempCanvas.width / 2;
     const canvasCenterY = tempCanvas.height / 2;
     const offsetX = canvasCenterX - graphCenterX;
     const offsetY = canvasCenterY - graphCenterY;
 
     // Draw the network (nodes and edges) on the temporary canvas centered
     nodes.forEach((node: any) => {
         ctx.fillStyle = 'blue';
         ctx.beginPath();
         ctx.arc(node.x + offsetX, node.y + offsetY, 5, 0, 2 * Math.PI);
         ctx.fill();
     });
     edges.forEach((edge: any) => {
         ctx.setLineDash([5, 3]);
         ctx.strokeStyle = 'blue';
         ctx.beginPath();
         ctx.moveTo(edge.from.x + offsetX, edge.from.y + offsetY);
         ctx.lineTo(edge.to.x + offsetX, edge.to.y + offsetY);
         ctx.stroke();
         ctx.setLineDash([]);
     });
 
     // Generate data URL from the temporary canvas and trigger download
     const imageURI = tempCanvas.toDataURL("image/png");

     try {
      // Convert Data URL to Blob
      const response = await fetch(imageURI);
      const blob = await response.blob();

      // Read the Blob as an ArrayBuffer
      const arrayBuffer = await blob.arrayBuffer();

      // Optionally convert the ArrayBuffer to a Uint8Array
      const uint8Array = new Uint8Array(arrayBuffer);

      // You can now use the Uint8Array or ArrayBuffer for further processing
      console.log("ArrayBuffer:", arrayBuffer);
      console.log("Uint8Array:", uint8Array);

      const data = {
        address: address,
        utf8ArrayImage: Array.from(uint8Array),
        points: nodes,
        edges: edges,
        description: '8 altar'
      };
  
      setIsMinting(true)
      try{
        const res = await fetch(ENDPOINT+'/store', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        const json = await res.json()
        console.log(json)
        setIsMinting(false)
  
        setTxHash(json.txHash)
      }catch(err){
        setError('ERR')
      }

      // // Trigger download (example using Blob URL)
      // const link = document.createElement('a');
      // link.download = "network-diagram.png";
      // link.href = URL.createObjectURL(blob);
      // document.body.appendChild(link);
      // link.click();
      // link.remove(); // Clean up

  } catch (err) {
      console.error('Error processing image data:', err);
  }

  }

  useEffect(() => {
    setNodes([])
    setEdges([])
  }, [view])

  useEffect(() => {
 
  }, [txHash, isMinting, view])

  const [selectedTokenId, setSelectedTokenId] = useState(null);

  useEffect(()=> {
    if(view == 2){
      setTimeout(async () => {
        const indexer = new SequenceIndexer('https://sepolia-indexer.sequence.app', 'AQAAAAAAAExvTNghWKVWJlmfiXZbHodfKGQ')
 
// here we query the Skyweaver contract address, but you can use any
const contractAddress = "0x838b46b2709a73d7d3321d0dafe78852c59d6381";
 
// query Sequence Indexer for all token details / supplies
// try any contract address you'd like :)
const filter = {
  contractAddress: contractAddress,
};
 
// query Sequence Indexer for all token transaction history on Polygon
const transactionHistory: any = await indexer.getTransactionHistory({
  filter: filter,
  includeMetadata: true
});
 
console.log("transaction history of contract:", transactionHistory);
const tempMeshes: any = []

for(let i = 0; i < transactionHistory.transactions.length; i++){
  const res = await fetch(`https://metadata.sequence.app/projects/19567/collections/278/tokens/${transactionHistory!.transactions[i]!.transfers[0]!.tokenIds[0]!}.json`)
  const json = await res.json()
  console.log(json)
  tempMeshes.push(
    <img
        key={transactionHistory!.transactions[i]!.transfers[0]!.tokenIds[0]} // Ensure a unique key for each item
        src={json.image}
        alt={json.name} // It's good to provide an alt text, if available
        onClick={() => setSelectedTokenId(transactionHistory!.transactions[i]!.transfers[0]!.tokenIds[0])} // Set the tokenId to state
        style={{ cursor: 'pointer' }} // Cursor pointer on hover
    />
  );
}

setMeshes(tempMeshes)
      }, 0)
    }
  }, [view])

  useEffect(() => {

  }, [meshes])
  
  const mintWithID = async () => {
    const data = {
      address: address,
      tokenID: selectedTokenId
    };

    setIsMinting(true)

    try {
      const res = await fetch(ENDPOINT+'/mint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const json = await res.json()
    console.log(json)
      setIsMinting(false)
    }catch(err){
      setError("Error")
    }
  }

  const [profileMeshes, setProfileMeshes] = useState<any>([])
  useEffect(() => {
    if(view == 1){
      setTimeout(async () => {

      const indexer = new SequenceIndexer('https://sepolia-indexer.sequence.app', 'AQAAAAAAAF_JvPALhBthL7VGn6jV0YDqaFY')
 
      // try any contract and account address you'd like :)
      const contractAddress = '0x838b46b2709a73d7d3321d0dafe78852c59d6381'
      const accountAddress = address
      
      // query Sequence Indexer for all nft balances of the account on Polygon
      const nftBalances = await indexer.getTokenBalances({
        contractAddress: contractAddress,
        accountAddress: accountAddress,
        includeMetadata: true
      })
      
      console.log('collection of items:', nftBalances)
      const tempMeshes = []
      for(let i = 0; i < nftBalances.balances.length; i++){
        const res = await fetch(`https://metadata.sequence.app/projects/19567/collections/278/tokens/${nftBalances!.balances[i]!.tokenID!}.json`)
        const json = await res.json()
        console.log(json)
        tempMeshes.push(
          json.image
          // <img
          //     key={nftBalances!.balances[i]!.tokenID} // Ensure a unique key for each item
          //     src={json.image}
          //     alt={json.name} // It's good to provide an alt text, if available
          // />
        );
      }
      
      setProfileMeshes(tempMeshes)
    }, 0)

    }
  }, [view])
  return (
    <div>
      {
    view > 0 && (
        <div style={{
          zIndex:1,
          width: 'fit-content',
            position: 'fixed',  // Fixes the position relative to the viewport
            left: '50%',        // Moves the left edge to the middle of the screen
            transform: 'translateX(-50%)',  // Corrects the centering by moving left by half the width
            top: '30px'         // Positioned 30px from the top
        }}>
            <span onClick={() => setView(2)} style={{
                borderBottom: view === 2 ? '1px solid aqua' : 'none'  // Conditional styling for 'feed'
            }}>feed</span>

            <span onClick={() => setView(1)} style={{
                borderBottom: view === 1 ? '1px solid aqua' : 'none'  // Conditional styling for 'profile'
            }}>profile</span>

            {view === 3 && (
                <span onClick={() => setView(3)} style={{
                    borderBottom: view === 3 ? '1px solid aqua' : 'none'  // Conditional styling for 'mint'
                }}>mint</span>
            )}
        </div>
    )
}
      {
        view > 0 && <div onClick={async () => {
          try {
            const sessions = await sequence.listSessions()

            for(let i = 0; i < sessions.length; i++){
              await sequence.dropSession({ sessionId: sessions[i].id })
            }
            setView(0)
          }catch(err){
            setView(0)
          }
        }}   style={{zIndex: 1, position: 'fixed', top: '30px', right:'30px'}}><span className='' style={{height: '100px'}}>sign out</span></div>
      }
      {
        view > 0 && <div style={{position: 'fixed', left: '30px', top: '30px'}}>
         <span>
    <a href={`https://sepolia.basescan.org/address/${address}`} target='_blank' style={{ textDecoration: 'none', color: 'inherit' }}>
        {address.slice(0,6)}...
    </a>
</span>
        </div>
      }
      {
        view == 0 
        ?
        <div style={{
          display: 'flex',          // Enables Flexbox
          flexDirection: 'column',  // Stacks children vertically
          alignItems: 'center',     // Centers children horizontally in the container
          justifyContent: 'center', // Centers children vertically in the container
          height: '100vh'           // Makes the container full height of the viewport
      }}> 
            <div style={{position: 'fixed', top: '30px', right: '30px'}}>
            <div className='gmail-login' style={{overflow: 'hidden', opacity: '0',width: '90px', position: 'absolute', zIndex: 1, height: '100px'}}>

           <GoogleLogin 
           nonce={sessionHash}
           key={sessionHash}
           onSuccess={handleGoogleLogin} shape="circle" width={230} />
         </div>

           <span className='sign-in' style={{background: 'green', height: '100px',color: 'white'}}>Gmail</span>
            {/* <button className='sign-in' onClick={() => signIn()} style={{background: 'aqua'}}><h1 style={{marginTop: '-10px', padding: '10px', color: 'grey', cursor: 'pointer'}}>sign in</h1></button> */}
            </div>
            <h1>h y p e r m e s h</h1>
            <br/>
            <h1>pointilize media</h1>
            <img src={radialSpaceCycle} width={400}/>
            <div style={{ position: 'fixed', bottom: '30px', left: '30px' }}>
              <h1 style={{
                color: 'yellow',
                textShadow: '4px 4px 2px black'
              }}>
                sepolia testnet
              </h1>
            </div>
            </div>
        :
        view == 1 
        ?
        <div style={{
            display: 'flex',          // Enables Flexbox
            flexDirection: 'column',  // Stacks children vertically
            alignItems: 'center',     // Centers children horizontally in the container
            justifyContent: 'center', // Centers children vertically in the container
        }}>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <ImageGrid images={profileMeshes} setView={setView}/>
  
        </div>
        : 
        view == 2 ?
        <div style={{

          display: 'flex',          // Enables Flexbox
          flexDirection: 'column',  // Stacks children vertically
          alignItems: 'center',     // Centers children horizontally in the container
          justifyContent: 'center', // Centers children vertically in the container
          height: '100vh'           // Makes the container full height of the viewport
      }}>
        <div style={{
          zIndex: 0,
          paddingTop: '500px',
          paddingLeft: '550px',
            maxHeight: '1000px', // Sets the maximum height of the div
            overflowY: 'auto', // Enables vertical scrolling
            position: 'fixed', // Fixes the div at a position
            top: '100', // Aligns the div at the top of the viewport
            margin: 'auto', // Aligns the div at the top of the viewport
            width: '100%' // Makes the div span the full width
        }}>
            {meshes.length > 0 ? <div>{meshes}</div> : <h1 style={{ color: 'lightgrey', textAlign: 'center' }}></h1>}
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div style={{height: '500px'}}></div>
        </div>


        {/* <div style={{position:'fixed', bottom: '30px', left: '30px'}}>
          <button className='user-btn' onClick={()=>console.log('user')}>user</button>
        </div> */}
        <div style={{position:'fixed', bottom: '30px', right: '30px'}}>
          {selectedTokenId && <button className='mint-btn' onClick={()=>mintWithID()}>{!error ? isMinting ? <Spinner />:'mint' : 'Error' }</button>}
          {/* <button className='sim-btn' style={{margin: '10px'}} onClick={() => console.log('sim')}>sim</button> */}
        </div>
      </div>
      : 
      <div  style={{
        display: 'flex',          // Enables Flexbox
        flexDirection: 'column',  // Stacks children vertically
        alignItems: 'center',     // Centers children horizontally in the container
        justifyContent: 'center', // Centers children vertically in the container
    }}>
      <div style={{position:'fixed', bottom: '30px', left: '30px'}}>
        <input className='choose-file' style={{margin: '10px'}} type="file" onChange={handleFileUpload} />
          </div>
          <br/>
          <div style={{position:'fixed', bottom: '30px', right: '30px'}}>
            {txHash && <span onClick={() => window.open(`https://sepolia.etherscan.io/tx/${txHash}`)}>{txHash.slice(0,5)}...</span>}
            <button className='mint-btn' onClick={()=>mint()}>{!error ? isMinting ? <Spinner />:'mint' : 'Error' }</button>
            <button className='download-btn' style={{margin: '10px'}} onClick={downloadCanvas}>download</button>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          {nodes.length == 0 && image && <div style={{position: 'absolute',marginLeft: '600px', marginTop: '100px'}}><h1>select points <br/>on the adjacent image</h1></div>}
          <canvas ref={canvasRef} width="1000" height="500" onClick={handleCanvasClick} />
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
      </div>
      }
    </div>
  );
}

export default App;
